import { apiQueryRate } from '@/resource'
import zotapayMixin from '@/mixins/page/deposit/zotapay'

export default {
  mixins: [zotapayMixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')))
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: Math.ceil(this.minLimit),
              currency: this.accountCurrency,
            })
          )
        )
      } else if (this.accountCurrency !== 'USC' && this.rateChange > this.maxLimit) {
        callback(new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxUSD, currency: 'USD' })))
      } else if (this.accountCurrency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: this.minLimitByUSC, currency: this.accountCurrency })
          )
        )
      } else if (this.accountCurrency === 'USC' && parseFloat(value) > this.getMaxUSCByRate(this.maxLimit, this.rate)) {
        callback(new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' })))
      } else {
        callback()
      }
    }
    return {
      zotaRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change',
          },
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur',
          },
        ],
      },
      validCurrencies: ['USD', 'USC'],
    }
  },
  mounted() {
    this.queryRate()
  },
  methods: {
    queryRate() {
      apiQueryRate()
        .then(resp => {
          if (resp.data.code == 0) this.rate = resp.data.data
        })
        .catch(err => {
          this.errorMessage(
            this.$t('deposit.default.rate.result.rateError', { oldCurrency: 'USD', newCurrency: 'THB' })
          )
        })
    },
  },
}
